<template>
    <v-dialog v-model="visible" max-width="600px">
        <v-card light>
            <v-card-title class="headline justify-center">
                Detalhes da cobrança - {{ value.charge_id }}
            </v-card-title>
            <v-card-text class="text-center">
                <div v-if="loading" class="ma-4 text-center">Aguarde, carregando...</div>

                <v-row v-if="customer">
                    <v-col cols="12" sm="6">
                        <v-icon class="mr-1">mdi-account-box</v-icon> {{ name }}
                    </v-col>
                    <v-col cols="12" sm="6">
                        <v-icon class="mr-1">mdi-file-document</v-icon> {{ doc }}
                    </v-col>
                </v-row>
                <v-row v-if="customer">
                    <v-col cols="12" sm="6">
                        <v-icon class="mr-1">mdi-email</v-icon> {{ email }}
                    </v-col>
                    <v-col cols="12" sm="6">
                        <v-icon class="mr-1">mdi-phone</v-icon> {{ phone }}
                    </v-col>
                </v-row>

                <v-card tile v-if="history.length">
                    <v-card-text :style="`height: ${$vuetify.breakpoint.height - 350}px; overflow-x: auto;`">
                        <v-timeline align-top dense>
                            <v-timeline-item :color="index % 2 ? 'pink' : 'teal lighten-3'" small v-for="(h, index) in history" :key="index">
                                <v-row class="pt-1">
                                    <v-col cols="3">
                                        <strong>{{ datetime(h.created_at, 'HH:mm') }}</strong>
                                    </v-col>
                                    <v-col>
                                        <strong>{{ h.message }}</strong>
                                        <div class="text-caption">
                                            {{ datetime(h.created_at) }}
                                        </div>
                                    </v-col>
                                </v-row>
                            </v-timeline-item>
                        </v-timeline>
                    </v-card-text>
                </v-card>

                <v-row class="mt-4">
                    <v-col cols="12" sm="6" v-if="boleto">
                        <a :href="boleto" target="_blank">Abrir boleto</a>
                    </v-col>
                    <v-col cols="12" sm="6" v-if="link">
                        <a :href="link" target="_blank">Compartilhar</a>
                    </v-col>
                </v-row>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="green" style="color: white;" @click="$emit('close')" block>Ok</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    name: 'CobrancaDetalhes',

    props: {
        visible: {
            type: Boolean,
            default: false
        },

        value: {
            type: Object,
            default: () => {}
        }
    },

    data: () => ({
        loading: false,
        dados: {}
    }),

    watch: {
        value(v) {
            v?.charge_id && this.consultar(v.charge_id);
        },
    },

    computed: {
        qrCode() {
            return this.dados?.payment?.banking_billet?.pix.qrcode_image;
        },

        boleto() {
            return this.dados?.payment?.banking_billet?.pdf.charge;
        },

        link() {
            return this.dados?.payment?.banking_billet?.link;
        },

        history() {
            return this.dados?.history || [];
        },

        customer() {
            return this.dados?.customer;
        },

        name() {
            return this.customer?.juridical_person?.corporate_name || this.customer.name;
        },

        email() {
            return this.customer.email;
        },

        doc() {
            return this.customer?.juridical_person?.cnpj || this.customer.cpf;
        },

        phone() {
            return this.customer.phone;
        },
    },

    methods: {
        consultar(cobranca_id) {
            const empresa_id = this.$route.params.emp;

            this.loading = true;
            this.dados = [];

            this.$http.post('assinaturas/cobranca-detalhes', { cobranca_id, empresa_id }).then(resp => {
                this.dados = resp.data.data;
            })
            .catch(() => {
                alert('Não foi possivel concluir a operação', 'warning');
            }).finally(() => (this.loading = false));
        },

        datetime(dh, format = 'DD/MM/YYYY HH:mm') {
            return dh ? this.moment(dh).format(format) : '';
        },
    },
}
</script>

<style scoped>
.v-card {
    padding: 0 15px 0 15px;
}
</style>
